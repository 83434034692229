import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import Layout from '@components/layout';
import SEO from '@components/seo';
import EdmusContext from '@components/application.provider';

export const query = graphql`
  {
    prismic {
      allContacts {
        edges {
          node {
            title
            imageHeader
            email
            _meta {
              lang
            }
          }
        }
      }
    }
  }
`;

const Contact = (props: any) => {
  const { language } = useContext(EdmusContext);
  const { allContacts } = props.data.prismic;
  const doc: any = allContacts.edges.find(
    (docs: any) => docs.node._meta.lang.slice(0, 2) === language
  );

  const mailTo = () => { if (doc.node.email) window.location.assign('mailto:' + doc.node.email) };

  if (!doc) return null;

  return (
    <Layout>
      <div className='contact'>
        <SEO title={doc.node.title} />
        <img onClick={() => mailTo()}
          className='img-bar clickable lazyloading'
          src={doc.node.imageHeader.url}
          alt={doc.node.imageHeader.alt}
        />
      </div>
    </Layout>
  );
};

export default Contact;
